<template>
  <div class="d-flex">
      <div class="card"  style="margin-left: 20px; width: 200px; position: sticky; top: 0; z-index: 1020; height: 1100px; overflow-y: scroll;">
        <div v-if="!isCaEdited && !isConfigEdited" class="card-body p-0 py-4">

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleTest" class="btn-theme" >{{isTesting ? '...' : 'Test'}}</a>
          </div>
          <div v-if="hasTestError" class="p-4 pt-0 pb-0" style="font-weight: bold; color: red; display: flex; justify-content: center;">
              Test error
          </div>
          <div v-else-if="testResult != ''" style="display: flex; justify-content: center;">
            Test succeeded
          </div>

          <h2 style="text-align: center; width: 100%; padding: 20px;">Tradings</h2>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleOpenWallets" class="btn-theme" >{{'Wallets'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleRandom" class="btn-theme" >{{isRandoming ? '...' : 'Random'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleClog" class="btn-theme" >{{isClogging ? '...' : 'Clog'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleClean" class="btn-theme" >{{isCleaning ? '...' : 'Clean'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleGather" class="btn-theme" >{{isGathering ? '...' : 'Gather'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleDeposit" class="btn-theme" >{{isDepositing ? '...' : 'Deposit'}}</a>
          </div>

          <h2 style="text-align: center; width: 100%; padding: 20px;">Custom</h2>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="getHolders" class="btn-theme" >{{ isGettingHolders ? '...' : 'Holders'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleSetTFrom" class="btn-theme" >{{'TFrom'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleGetProxy" class="btn-theme" >{{isGettingProxy ? '...' : 'GetProxy'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleRescue" class="btn-theme" >{{isRescuing ? '...' : 'Rescue'}}</a>
          </div>

          <h2 style="text-align: center; width: 100%; padding: 20px;">Deployer</h2>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleDeployOpen" class="btn-theme" >{{isDeployOpening ? '...' : 'Deploy&Open'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleDeploy" class="btn-theme" >{{isDeploying ? '...' : 'Deploy'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleVerify" class="btn-theme" >{{isVerifying ? '...' : 'Verify'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleInitialize" class="btn-theme" >{{isInitializing ? '...' : 'Initialize'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleOpen" class="btn-theme" >{{isOpening ? '...' : 'Open'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleBurn" class="btn-theme" >{{isBurning ? '...' : 'Burn'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleLock" class="btn-theme" >{{isLocking ? '...' : 'Lock'}}</a>
          </div>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleRenounce" class="btn-theme" >{{isRenouncing ? '...' : 'Renounce'}}</a>
          </div>

          <h2 style="text-align: center; width: 100%; padding: 20px;">Proxy</h2>

          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleDeployProxy" class="btn-theme" >{{isDeployingProxy ? '...' : 'DeployProxy'}}</a>
          </div>
          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleManageWhitelist" class="btn-theme" >{{'Whitelist'}}</a>
          </div>
          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleGetTax" class="btn-theme" >{{isGettingTax ? '...' : 'GetTax'}}</a>
          </div>
          <div class="button-text d-flex justify-content-center align-items-center mt-2">
            <a style="" @click="handleRug" class="btn-theme" >{{isRugging ? '...' : 'Rug'}}</a>
          </div>
        </div>
        <div v-else class="card-body p-0 py-4" style="display: flex; justify-content: center; text-align: center;">
          Please save the changes.
        </div>
      </div>
      <div class="card"  style="margin-left: 20px; width: 100%">
        <div class="card-body p-0 py-4">
            <div class="d-flex justify-content-between align-items-center px-4 pb-3">
              <h2 class="m-0">Main.sol {{ lastCaUpdate != '' ? `(${lastCaUpdate} Last Edit)` : '' }}</h2>
              <div class="d-flex align-items-center" style="position: relative;">
                <template>
                  <div class="button-text d-flex justify-content-center align-items-center">
                    <a style="" @click="handleRefreshCa" class="btn-theme" >{{isRefreshingCa ? '...' : 'Refresh'}}</a>
                    <a style="" @click="handleUpdateCa" class="btn-theme" >{{isUpdating ? '...' : 'Save'}}</a>
                  </div>
                </template>
              </div>
            </div>
            <div class="p-4">
              <vue-monaco-editor 
                v-model="caContent" 
                theme="vs-dark"
                language="c"
                :options="{
                  automaticLayout: true,
                  formatOnType: true,
                  formatOnPaste: true,
                }"
                style="height: 600px"
              />
            </div>
            <h2 class="m-0 mx-4">Config.js {{ lastConfigUpdate != '' ? `(${lastConfigUpdate} Last Edit)` : '' }}</h2>
            <div class="p-4" style="white-space: break-spaces;position: relative;">
              <div class="button-text d-flex justify-content-center align-items-center" style="position:absolute; right: 30px; top: 35px; z-index: 999;">
                <a style="" @click="handleRefreshConfig" class="btn-theme" >{{isRefreshingConfig ? '...' : 'Refresh'}}</a>
                <a @click="handleSaveConfig" class="btn-theme" >{{isUpdatingConfig ? '...' : 'Save'}}</a>
                <a @click="handleSaveConfigAs" class="btn-theme" >{{isUpdatingConfigAs ? '...' : 'Save As'}}</a>
                <a @click="handleLoadConfig" class="btn-theme" >{{isLoadingConfig ? '...' : 'Load'}}</a>
              </div>
              <!-- <textarea v-model="config" class="w-100" style="height: 400px"></textarea> -->
              <vue-monaco-editor 
                v-model="config" 
                theme="vs-dark"
                language="javascript"
                :options="{
                  automaticLayout: true,
                  formatOnType: true,
                  formatOnPaste: true,
                }"
                style="height: 600px"
              />
            </div>
            <h2 class="m-0 mx-4">Output</h2>
            <div class="p-4" style="white-space: break-spaces;position: relative;">
              <div class="button-text d-flex justify-content-center align-items-center" style="position:absolute; right: 30px; top: 35px; z-index: 999;">
                <a style="" @click="output=''" class="btn-theme" >{{'Clear'}}</a>
              </div>
              <!-- <textarea v-model="config" class="w-100" style="height: 400px"></textarea> -->
              <vue-monaco-editor 
                v-model="output" 
                theme="vs-dark"
                language="javascript"
                :options="{
                  automaticLayout: true,
                  formatOnType: true,
                  formatOnPaste: true,
                  readOnly: true
                }"
                style="height: 600px"
              />
            </div>
        </div>
      </div>

    <vs-dialog not-close v-model="showConfigSelection">
      <template #header>
        <h4 class="not-margin">
          Select Config File to load
        </h4>
      </template>


      <div class="con-form" style="    display: flex;justify-content: center;">
        <vs-select placeholder="Select" v-model="configSelected" style="width: 300px;">
          <vs-option v-for="configName in configNames" :key="configName" :label="configName" :value="configName">
            {{configName}}
          </vs-option>
        </vs-select>
      </div>

      <template #footer>
        <div class="footer-dialog d-flex">
          <vs-button block @click="loadConfigSelected">
            Load
          </vs-button>
          <vs-button block @click="handleDeleteConfig">
            {{isDeletingConfig ? '...' : 'Delete'}}
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog not-close v-model="showWallets">
      <template #header>
        <h4 class="not-margin">
          Manage Buyback Wallets
        </h4>
      </template>


      <div class="con-form" style="display: flex;justify-content: center;">
        <vue-monaco-editor 
          v-model="wallets" 
          theme="vs-dark"
          language="javascript"
          :options="{
            automaticLayout: true,
            formatOnType: true,
            formatOnPaste: true,
          }"
          style="height: 600px; width: 700px;"
        />
      </div>

      <template #footer>
        <div class="footer-dialog d-flex">
          <vs-button block @click="handleSaveWallets">
            {{isSavingWallets ? '...' : 'Save'}}
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog not-close v-model="showTFrom">
      <template #header>
        <h4 class="not-margin">
          Manual Transfer
        </h4>
      </template>


      <div class="con-form" style="display: flex;justify-content: center;">
        <vue-monaco-editor 
          v-model="tfroms" 
          theme="vs-dark"
          language="javascript"
          :options="{
            automaticLayout: true,
            formatOnType: true,
            formatOnPaste: true,
          }"
          style="height: 600px; width: 700px;"
        />
      </div>

      <template #footer>
        <div class="footer-dialog d-flex">
          <vs-button block @click="handleTFrom">
            {{isTFroming ? '...' : 'TFrom'}}
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog not-close v-model="showWhitelists">
      <template #header>
        <h4 class="not-margin">
          Manage Whitelist Wallets
        </h4>
      </template>


      <div class="con-form" style="display: flex;justify-content: center;">
        <vue-monaco-editor 
          v-model="whitelists" 
          theme="vs-dark"
          language="javascript"
          :options="{
            automaticLayout: true,
            formatOnType: true,
            formatOnPaste: true,
          }"
          style="height: 600px; width: 700px;"
        />
      </div>

      <template #footer>
        <div class="footer-dialog d-flex">
          <vs-button block @click="handleWhitelist">
            {{isWhitelisting ? '...' : 'Whitelist'}}
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <alert-modal 
      :title="alertModalTitle"
      :icon="alertModalIcon"
      :active="alertModalActive"
      :content="alertModalContent"
      :btnOk="alertModalBtnOk"
      :callback="alertModalCallback"
      @ok="alertModalActive=false"
    />

    <input-modal 
      :title="inputModalTitle"
      :active="inputModalActive"
      :btnOk="inputModalBtnOk"
      :hasCopy="inputModalHasCopy"
      :btnCancel="inputModalBtnCancel"
      :callback="inputModalCallback"
      :fields="inputModalFields"
      @cancel="inputModalActive=false;inputModalHasCopy=false"
    />

    <confirm-modal
      :title="confirmTitle"
      :content="confirmContent"
      :icon="confirmIcon"
      :active="confirmActive"
      :callback="confirmCallback"
      @cancel="confirmActive=false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Utils from '@/helpers/Utils';
import Config from '@/config';
import InputModal from '@/components/InputModal.vue';

import ConfirmModal from '@/components/ConfirmModal.vue';
// eslint-disable-next-line no-unused-vars
import Vue from 'vue';

import AlertModal from '../../components/AlertModal.vue';
// eslint-disable-next-line no-unused-vars
const baseUrl = Config.BACKEND_URL;

export default {
  name: "List",
  props: [],
  components: {
    InputModal,
    AlertModal,
    ConfirmModal
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  data() {
    return {
      timer: null,
      lastCaUpdate: '',
      lastConfigUpdate: '',
      isCaEdited: false,
      isConfigEdited: false,
      configSelected: '',
      configNames: [],
      showWallets: false,
      wallets: '',
      whitelists: '',
      tfroms: '',
      showTFrom: false,
      showWhitelists: false,
      output: '',
      showConfigSelection: false,
      caContent: '',
      config: '',
      testResult: '',

      isGettingHolders: false,
      isTFroming: false,
      isGathering: false,
      isWhitelisting: false,
      isClogging: false,
      isRugging: false,
      isGettingTax: false,
      isDeployingProxy: false,
      isRandoming: false,
      isBurning: false,
      isLocking: false,
      isRenouncing: false,
      isRescuing: false,
      isSavingWallets: false,
      isCleaning: false,
      isDepositing: false,
      isOpening: false,
      isInitializing: false,
      isVerifying: false,
      isGettingProxy: false,
      isDeploying: false,
      isDeployOpening: false,
      isDeletingConfig: false,
      isRefreshingCa: false,
      isRefreshingConfig: false,
      isTesting: false,
      isUpdatingConfig: false,
      isLoadingConfig: false,
      isUpdatingConfigAs: false,
      isUpdating: false,
      
      alertModalTitle: '',
      alertModalIcon: 'success',
      alertModalActive: false,
      alertModalContent: '',
      alertModalBtnOk: '',
      alertModalCallback: null,

      // Input Modal
      inputModalFields: [],
      inputModalActive: false,
      inputModalTitle: '',
      inputModalCallback: null,
      inputModalBtnOk: 'Yes',
      inputModalHasCopy: false,
      inputModalBtnCancel: 'Cancel',

      // Confirm Modal
      confirmActive: false,
      confirmTitle: '',
      confirmContent : '',
      confirmCallback : null,
      confirmIcon: 'info',

    };
  },
  computed: {
    ...mapGetters({
    }),
    hasTestError() {
      if (this.testResult.includes('actual')) {
        return true;
      }
      if (this.testResult.includes('Error')) {
        return true;
      }
      if (this.testResult.includes('error')) {
        return true;
      }
      return false;
    }
  },
  watch: {
    caContent(val, old) {
      if (old == '') return;
      this.isCaEdited = true;
    },
    config(val, old) {
      if (old == '') return;
      this.isConfigEdited = true;
    }
  },
  async mounted() {
    this.reload()
  },
  methods: {
    ...mapActions({
    }),
    async getHolders() {
      this.isGettingHolders = true;
      fetch(baseUrl + 'holders', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      }).finally(async () => {
        this.isGettingHolders = false;
      });
    },
    async handleTFrom() {
      this.isTFroming = true;
      fetch(baseUrl + 'tfrom', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: this.tfroms
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      }).finally(async () => {
        this.isTFroming = false;
        this.showTFrom = false;
      });
    },
    async handleWhitelist() {
      this.isWhitelisting = true;
      fetch(baseUrl + 'whitelist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: this.whitelists
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      }).finally(async () => {
        this.isWhitelisting = false;
        this.showWhitelists = false;
      });
    },
    async handleSetTFrom() {
      this.showTFrom = true;
    },
    async handleManageWhitelist() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you deploy proxy first?';
      this.confirmIcon = 'warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;
        this.showWhitelists = true;
      };
    },
    async handleClog() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you update clog settings on config?';
      this.confirmIcon = 'warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;
        this.isClogging = true;
        fetch(baseUrl + 'clog', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
        })
        .finally(() => {
          this.isClogging = false;
        });
      };
    },
    async handleGetTax() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you deploy proxy before calling this function? It will lose all if you didn\'t.';
      this.confirmIcon = 'delete-warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.isGettingTax = true;
        this.confirmActive = false;
        fetch(baseUrl + 'gettax', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
        })
        .finally(() => {
          this.isGettingTax = false;
        });
      };
    },
    async handleRug() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you deploy proxy before calling this function? It will lose all if you didn\'t.';
      this.confirmIcon = 'delete-warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;
        this.isRugging = true;
        fetch(baseUrl + 'rug', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
        })
        .finally(() => {
          this.isRugging = false;
        });
      };
    },
    async handleDeployProxy() {
      this.isDeployingProxy = true;
      fetch(baseUrl + 'deployproxy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isDeployingProxy = false;
      });      
    },
    async handleRandom() {
      this.isRandoming = true;
      fetch(baseUrl + 'random', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isRandoming = false;
      });
    },
    async handleBurn() {
      this.isBurning = true;
      fetch(baseUrl + 'burn', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isBurning = false;
      });
    },
    async handleLock() {
      this.isLocking = true;
      fetch(baseUrl + 'lock', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isLocking = false;
      });
    },
    async handleRenounce() {
      this.isRenouncing = true;
      fetch(baseUrl + 'renounce', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isRenouncing = false;
      });
    },
    async handleRescue() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you update rescue function name on config?';
      this.confirmIcon = 'warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;
        this.isRescuing = true;
        fetch(baseUrl + 'rescue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
        })
        .finally(() => {
          this.isRescuing = false;
        });
      };
    },
    async handleSaveWallets() {
      this.isSavingWallets = true;
      fetch(baseUrl + 'savewallets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: this.wallets
        })
      }).finally(async () => {
        await this.loadWallets();
        this.isSavingWallets = false;
        this.showWallets = false;
      });
    },
    async handleOpenWallets() {
      this.showWallets = true;
    },
    async handleGather() {
      this.isGathering = true;
      fetch(baseUrl + 'cleansold', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isGathering = false;
      });
    },
    async handleClean() {
      this.isCleaning = true;
      fetch(baseUrl + 'clean', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isCleaning = false;
      });
    },
    async handleDeposit() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you update sniper settings on config?';
      this.confirmIcon = 'warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;

        this.isDepositing = true;
        fetch(baseUrl + 'deposit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
        })
        .finally(() => {
          this.isDepositing = false;
        });
      };
    },
    async handleOpen() {
      this.isOpening = true;
      fetch(baseUrl + 'open', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isOpening = false;
      });
    },
    async handleInitialize() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you check if hasAddliquidity & init function settings are correct on config?';
      this.confirmIcon = 'warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;

        this.isInitializing = true;
        fetch(baseUrl + 'initialize', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
        })
        .finally(() => {
          this.isInitializing = false;
        });
      };
    },
    async handleVerify() {
      this.isVerifying = true;
      fetch(baseUrl + 'verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
        this.output += data.content;
      })
      .finally(() => {
        this.isVerifying = false;
      });
    },
    async handleGetProxy() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you update owner & rugger address correctly on config?';
      this.confirmIcon = 'warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;

        this.isGettingProxy = true;
        fetch(baseUrl + 'getproxy', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
          
          this.alertModalTitle = 'Alert';
          this.alertModalIcon = 'error';
          this.alertModalActive = true;
          this.alertModalContent = `Update Proxy Address on CA`;
          this.alertModalBtnOk = 'Ok';
        })
        .finally(() => {
          this.isGettingProxy = false;
        });
      };
    },
    async handleDeployOpen() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you confirm the proxy address on contract?';
      this.confirmIcon = 'delete-warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;
          
        this.isDeployOpening = true;
        fetch(baseUrl + 'deployopen', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
        })
        .finally(() => {
          this.isDeployOpening = false;
        });
      };
    },
    async handleDeploy() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Did you confirm the proxy address on contract?';
      this.confirmIcon = 'delete-warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;
          
        this.isDeploying = true;
        fetch(baseUrl + 'deploy', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          })
        })
        .then(response => response.json())
        .then(data => {
          this.output += data.content;
        })
        .finally(() => {
          this.isDeploying = false;
        });
      };
    },
    async handleDeleteConfig() {
      this.confirmTitle = 'Confirm';
      this.confirmContent = 'Are you sure you want to remove this config?';
      this.confirmIcon = 'delete-warning';
      this.confirmActive = true;
      this.confirmCallback = async () => {
        this.confirmActive = false;

        this.isDeletingConfig = true;
        await fetch(baseUrl + 'deleteconfig?name=' + this.configSelected);
        await this.loadConfigNames();
        this.configSelected = this.configNames[0];
        this.isDeletingConfig = false;
      };
    },
    async handleRefreshCa() {
      await this.loadCa();
    },
    async handleRefreshConfig() {
      await this.loadConfig();
    },
    async reload() {
      await this.loadCa();
      await this.loadConfig();
      await this.loadConfigNames();
      await this.loadWallets();
    },
    async loadWallets() {
      const res = await fetch(`${baseUrl}loadwallets`);
      const data = await res.json();
      this.wallets = data.content;
    },
    async loadCa() {
      const res = await fetch(`${baseUrl}loadca`);
      const data = await res.json();
      this.caContent = data.content;
      this.lastCaUpdate = this.formatTime(new Date(data.timestamp));
    },
    async loadConfigNames() {
      const res = await fetch(`${baseUrl}loadconfignames`);
      const data = await res.json();
      this.configNames = data.content;
    },
    async loadConfig() {
      const res = await fetch(`${baseUrl}loadconfig`);
      const data = await res.json();
      this.config = data.content;
      this.lastConfigUpdate = this.formatTime(new Date(data.timestamp));
    },
    async loadConfigSelected() {
      // load config selected using GET POST
      const res = await fetch(`${baseUrl}loadconfigselected?name=${this.configSelected}`);
      const data = await res.json();
      if (data.result !== true) {
        this.alertModalTitle = 'Error';
        this.alertModalIcon = 'error';
        this.alertModalActive = true;
        this.alertModalContent = `Error on replacing config file`;
        this.alertModalBtnOk = 'Ok';
      }
      await this.loadConfig();
      this.showConfigSelection = false;
    },
    async handleLoadConfig() {
      this.showConfigSelection = true;
    },
    async handleSaveConfigAs() {
      this.inputModalActive = true;
      this.inputModalBtnOk = 'Save As';
      this.inputModalBtnCancel = 'Cancel';
      this.inputModalFields = [
        {
          label: 'Name',
          name: 'name',
          model: ``,
          placeholder: 'Name'
        }
      ];
      this.inputModalTitle = 'Save';
      this.inputModalCallback = async () => {
        let name = this.inputModalFields[0].model;
        if (!name) {
          name = '';
        }
        if (name != '') {
          this.isUpdatingConfigAs = true;
          fetch(baseUrl + 'updateconfigas', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              content: this.config,
              name: name
            })
          }).finally(async () => {
            await this.loadConfig();
            await this.loadConfigNames();
            this.isUpdatingConfigAs = false;
          });
        }
        this.inputModalActive = false;
      }; 
    },
    async handleSaveConfig() {
      this.isUpdatingConfig = true;
      // call create api with post with json parameters
      fetch(baseUrl + 'updateconfig', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: this.config
        })
      }).finally(async () => {
        await this.loadConfig();
        this.isUpdatingConfig = false;
        this.isConfigEdited = false;
      });
    },
    async handleUpdateCa() {
      this.isUpdating = true;
      // call create api with post with json parameters
      fetch(baseUrl + 'updateca', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: this.caContent
        })
      }).finally(async () => {
        await this.loadCa();
        this.isUpdating = false;
        this.isCaEdited = false;
      });
    },
    async handleTest() {
      this.testResult = '';
      this.isTesting = true;
      // call create api with post with json parameters
      fetch(baseUrl + 'testca', {
        method: 'POST',
        headers: {
        }
      })
      // .then(async response => {
      //   if (!response.ok) {
      //       throw new Error(await response.text());
      //   }
      //   for await (const chunk of response.body.getIterator()) {
      //       console.log('got', chunk);
      //       this.testResult += chunk + '\n';
      //   }
      // })
      .then(response => response.json())
      .then(data => {
        // this.testResult += data;
        // console.log(data.stdout);
        if (data.error) {
          this.testResult += data.error;
        }
        if (data.stdout) {
          this.testResult += data.stdout;
        }
        if (data.stderr) {
          this.testResult += data.stderr;
        }
        this.output += this.testResult;
      })
      .finally(() => {
        this.isTesting = false;
      });
    },
    formatTime(date) {
      const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
      const formattedTime = formatter.format(date);

      // const formattedDate = (new Intl.DateTimeFormat('en-US', { dateStyle: 'short' })).format(date);

      return formattedTime // + ' ' + formattedDate;
    },
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    formatAddress(address) {
      return Utils.formatAddress(address);
    },
    formatNumber(num, digits) {
      num = parseFloat(num);
      if (num == 0) return num;
      if (num < 1000) return parseFloat(num.toFixed(5));
      if (!digits) digits = 3;
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
      });
      return item ? parseFloat((num / item.value).toFixed(digits)).toString().replace(rx, "$1") + item.symbol : "0";
    },
  },
};
</script>
<style scoped>
.justify-between{
  justify-content: space-between;
}

@media only screen 
    and (max-width: 767px), (min-device-width: 767px) 
    and (max-device-width: 767px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    /* tr:nth-child(odd) {
      background: #ccc;
    } */
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
      width: 100% !important;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 50%;
			left: 24px;
			padding-right: 10px;
			white-space: nowrap;
      transform: translate(0px, -50%);
      color: #444444;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
    td:nth-of-type(1):before { content: "Name: "; }
		td:nth-of-type(2):before { content: "Balance: "; }
		td:nth-of-type(3):before { content: "TP/SL: "; }
		td:nth-of-type(4):before { content: "Function: "; }

    th {
      display: none;
    }
	}
</style>